
/**
 * 封装 fetch
 */
export default function request(method, url, body) {
    //前缀  
    //本地
    method = method.toUpperCase();
    if (method === 'GET') {
        // fetch的GET不允许有body，参数只能放在url中
        body = undefined;
    } else {
        body = body && JSON.stringify(body);
    }
    return fetch(process.env.REACT_APP_URL + url, {
        method,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': localStorage.getItem('Authorization') || '', // 从localStorage中获取access token
            'Language': localStorage.getItem('language')?'en':'',
        },
        body,
        mode: 'cors'
    }).then((res) => {
        if(res.status === 401){
            window.location.href = process.env.REACT_APP_URL + '/Login';
            // alert('登录失效，请重新登录！');
            return;
        }
        return res.json();
    });
}

// GET 请求
export const get = url => request('GET', url);
// POST 请求
export const post = (url, body) => request('POST', url, body);
// PUT 上传
export const put = (url, body) => request('PUT', url, body);
// DELETE 删除
export const del = (url, body) => request('DELETE', url, body);