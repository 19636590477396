import "./OperateFingerpost.css";

import "../../i18n";

const OperateFingerpost = () => {

    //墨水屏办公本用户指南
    const downRealsureMoShuiPinZhiNan = () => {
        window.open(process.env.REACT_APP_OSS_PREFIX + 'Realsure%E7%94%A8%E6%88%B7%E6%89%8B%E5%86%8C.pdf', '_blank');
    }

    //全向麦用户指南
    const downRealsureQuanXiangMaiZhiNan = () => {
        window.open(process.env.REACT_APP_OSS_PREFIX + '%E4%BC%9A%E8%AF%BA%E9%9F%B3%E9%A2%91S1%E7%94%A8%E6%88%B7%E6%8C%87%E5%8D%97.pdf', '_blank');
    }


    return (
        <>
            <div style={{ marginBottom: "-4px" }}>
                <img className="operate_fingerpost_guanyubanner" alt="businesscooperationbanner" src={process.env.REACT_APP_OSS_PREFIX + "OperateFingerpost.png"} />
            </div>
            <div className="operate_fingerpost_content">
                <div className="operate_fingerpost_title">
                    <div className="operate_fingerpost_title_huinuo">操作指南</div>
                </div>
                <div>
                    <div className="operate_fingerpost_content_content">
                        <div onClick={downRealsureMoShuiPinZhiNan} className="operate_fingerpost_content_content_div">
                            <img alt="down" src={process.env.REACT_APP_OSS_PREFIX + "OperateFingerpostDown.png"} />
                            <div className="operate_fingerpost_content_content_font">墨水屏办公本用户指南</div>
                        </div>
                        <div onClick={downRealsureQuanXiangMaiZhiNan} className="operate_fingerpost_content_content_div">
                            <img alt="down" src={process.env.REACT_APP_OSS_PREFIX + "OperateFingerpostDown.png"} />
                            <div className="operate_fingerpost_content_content_font">全向麦用户指南</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default OperateFingerpost;