import "./UnderstandRealsure.css";

import "../../i18n";

const UnderstandRealsure = () => {

    return (
        <>
            <div style={{marginBottom:"-4px"}}>
                <img className="understand_realsure_guanyubanner" alt="businesscooperationbanner" src={process.env.REACT_APP_OSS_PREFIX + "UnderstandRealsure.png"} />
            </div>
            <div className="understand_realsure_content">
                <div className="understand_realsure_title">
                    <div className="understand_realsure_title_huinuo">会诺</div>
                    <div className="understand_realsure_title_real"></div>
                </div>
                <div>
                    <div className="understand_realsure_content_content">
                        会诺致力于办公美学的革新，打造智能会议美学第一品牌。让沟通、记录自由切换，满足多元化移动办公场景。主要产品有：墨水屏办公本、会议音频全向麦克风等智能办公数码产品。
                    </div>
                    <div className="understand_realsure_content_title">
                        颜值会议办公开创者
                    </div>
                    <div className="understand_realsure_content_content">
                        办公方式多元化导致对会议类产品需求不断增长，无论是云办公的方便快捷、抑或是办公软件的层出不穷，
                        智能会议专业硬件端口产品的诞生与迭代是中国办公时代通向智能办公的必经之路。
                    </div>
                    <div className="understand_realsure_content_content">
                        会诺的诞生，源于一个美好的愿景。在会诺人眼中，会议办公已然是现代社会工作生活不可或缺的一部分，
                        但会议办公硬件不应该是一个冰冷乏味的名词，它的背后承载着现代人对于工作的热情与美好理想，也是现代办公高速运转的基础与纽带之一。
                    </div>
                    <div className="understand_realsure_content_content">
                        区别于传统会议办公，会诺从智能会议办公出发,定义新时代之下的智能会议美学。针对会议办公及时性、
                        完整性以及高效协同性，会诺通过强大的研发及生产落地的全链路打通，构建了完善的产品体系，有效满足多元移动办公场景，
                        将科技与颜值美学实现完美融合，让你的办公生活“眼前一亮”。
                    </div>
                    <div className="understand_realsure_content_title">
                        会诺实干家<br />
                    </div>
                    <div className="understand_realsure_content_content">
                        01.自研自产，拥有产品主导性<br />
                        02.以“用户体验”为核心的产品创新战略<br />
                        03.专业级别的研发团队及追求<br />
                        04.用心打造，细节彰显品质<br />
                    </div>
                </div>
            </div>
        </>
    );
}
export default UnderstandRealsure;