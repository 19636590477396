import "./ServeMaintain.css";

import "../../i18n";

const ServeMaintain = () => {

    return (
        <>
            <div style={{ marginBottom: "-4px" }}>
                <img className="serve_maintain_guanyubanner" alt="businesscooperationbanner" src={process.env.REACT_APP_OSS_PREFIX + "ServeMaintain.png"} />
            </div>
            <div className="serve_maintain_content">
                <div className="serve_maintain_title">
                    <div className="serve_maintain_title_huinuo">服务和维修</div>
                </div>
                <div>
                    <div className="serve_maintain_content_title">
                        服务和维修：
                    </div>
                    <div className="serve_maintain_content_content">
                        售后联系方式：sales1@yitoadigital.com
                    </div>
                    <br />
                    <div className="serve_maintain_content_title">
                        保修范围：
                    </div>
                    <div className="serve_maintain_content_content">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.主机：保修期 1 年，自购买之日起一年内（以购买发票日期为准），凡在正常操作情况下，由于产品本身质量问题引起的性能故障，如未经拆修，可以享受免费维修服务；<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.自购买之日起一年后，产品出现性能故障或更换整机部件，可享受收费式维修服务。<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.以上保修或维修，仅限于电器元件的故障，主要包括硬件主板和显示屏，不包括外壳、说明书、包装物等易耗品及配件。<br />
                    </div>
                    <br />
                    <div className="serve_maintain_content_title">
                        非保修范围：
                    </div>
                    <div className="serve_maintain_content_content">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;以下情况不属于保修范围，即使在适用时间范围内，保修条款将不再适用，但仍可享受收费式维修服务。<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.未经我司许可，私自拆卸、维修导致的故障机；<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.涂改或丢失产品序列号的故障机；<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.因人为原因，或在非规定的环境下使用，造成的故障或损坏的故障机，例如过于潮湿或干燥、高温，电压或电流不稳定，零地电压过大等；<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.因意外原因或使用不当造成的损坏，包括机器进液、破裂、碰撞、不正常插拔、虫害等；<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.自然消耗，包括外壳、接插部件的自然消耗、磨损及老化；<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.因不可抗力造成的损坏，如火灾、水灾、地震等自然灾害；<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.在 OTA 允许范围之外，对本机的软件进行操作造成的故障机。
                    </div>
                </div>
            </div>
        </>
    );
}
export default ServeMaintain;