import "./Homepage.css";

import { useNavigate } from "react-router";

const Homepage = () => {
    const navigate = useNavigate()

    const threeClick = () => {
        navigate('/Home/CompanyIntroductionDetails?type=4&uuid=');
    }

      //跳转墨水瓶
  const toInkScreen = (uuid) => {
    navigate('/Home/InkScreen?uuid=' + uuid);
    window.scrollTo({ top: 1});
  }

    return (
        <>
            <div className="homepage_center_img">
                <img onClick={()=>{toInkScreen("1010")}} className="homepage_img" alt="bannner" src={process.env.REACT_APP_OSS_PREFIX + "homepage_1.png"} />
                <img onClick={()=>{toInkScreen("s1")}} className="homepage_img" alt="bannner" src={process.env.REACT_APP_OSS_PREFIX + "homepage_2.png"} />
            </div>
        </>
    );
}
export default Homepage;