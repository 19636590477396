import "./BusinesScooperation.css";

import businesscooperationbanner from "../../../accets/contactus/businesscooperation/businesscooperationbanner.png"
import ditu from "../../../accets/contactus/businesscooperation/ditu.jpg"

import "../../../i18n";
import { useTranslation } from "react-i18next";

const BusinesScooperation = () => {
    const { t } = useTranslation();

    return (
        <>
            <div>
                <img className="business_cooperation_guanyubanner" alt="businesscooperationbanner" src={businesscooperationbanner} />
            </div>
            <div className="business_cooperation_content">
                <div className="business_cooperation_content_left">
                    <div className="business_cooperation_content_left_title">{t('联系我们')}</div>
                    <div className="business_cooperation_content_left_content">{t('联系我们地址')}</div>
                    <div className="business_cooperation_content_left_content">{t('联系我们电话')}</div>
                    <div className="business_cooperation_content_left_content">{t('联系我们邮箱')}</div>
                </div>
                <div className="business_cooperation_content_right">
                    <img className="compant_introduction_guanyubanner" alt="ditu" src={ditu} />
                </div>
            </div>
        </>
    );
}
export default BusinesScooperation;