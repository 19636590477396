import { useEffect } from "react";
import { useNavigate } from 'react-router-dom'


const ToHome = () => {
    const navigate = useNavigate();

    useEffect(()=>{
        navigate('/Home/Homepage');
    });

    return (
        <>
        </>
    );
}
export default ToHome;