import React from 'react'
import history from "./history";
// 导入路由
import { unstable_HistoryRouter as Router, Route, Routes } from 'react-router-dom'

import Home from '../views/Home'
import BusinesScooperation from '../views/contactus/businesscooperation/BusinesScooperation';
import HomePage from '../views/homepage/Homepage';
import ToHome from '../views/ToHome';
import InkScreen from '../views/inkScreen/InkScreen';
import UnderstandRealsure from '../views/understandrealsure/UnderstandRealsure';
import ServeMaintain from '../views/servemaintain/ServeMaintain';
import OperateFingerpost from '../views/operatefingerpost/OperateFingerpost';

const MyRoute = () => {
    return (
        <Router history={history}>
            <Routes>
                <Route path="*" exact={false} element={<ToHome />} />
                <Route path="/Home" exact={false} element={<Home />} >
                    <Route exact={false} path='/Home/Homepage' element={<HomePage />} /> 
                    <Route exact={false} path='/Home/BusinesScooperation' element={<BusinesScooperation />} /> 
                    <Route exact={false} path='/Home/InkScreen' element={<InkScreen />} /> 
                    <Route exact={false} path='/Home/UnderstandRealsure' element={<UnderstandRealsure />} /> 
                    <Route exact={false} path='/Home/ServeMaintain' element={<ServeMaintain />} /> 
                    <Route exact={false} path='/Home/OperateFingerpost' element={<OperateFingerpost />} /> 
                </Route>
            </Routes>
        </Router>
    )
}

export default MyRoute;