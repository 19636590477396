import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MyRoute from './router/index';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';

import 'dayjs/locale/zh-cn';

import enUS from 'antd/locale/en_US';
import zhCN from 'antd/locale/zh_CN';

import "normalize.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <ConfigProvider locale={localStorage.getItem('language') ? enUS : zhCN}>
                <MyRoute />
        </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
