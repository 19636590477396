import React, { useEffect, useState } from 'react';
// import { FileOutlined, PieChartOutlined, UserOutlined, DesktopOutlined } from '@ant-design/icons';
import { Layout, Popover, ConfigProvider, Menu, Affix, message } from 'antd';
import "./indexHome.css";
import { Outlet, useNavigate } from 'react-router-dom'
import enUS from 'antd/locale/en_US';
import zhCN from 'antd/locale/zh_CN';

import titlepng from '../accets/home/titlepng.png'
import z101 from '../accets/home/10-1.png'
import z103 from '../accets/home/10-3.png'
import z78 from '../accets/home/7-8.png'
import yinpin from '../accets/home/yinpin.png'
import guanbi from '../accets/home/guanbi.png'
import gengduo from '../accets/home/gengduo.png'
import $ from 'jquery'
import request from '../utils/request';

import "../i18n";
import { useTranslation } from "react-i18next";


const { Header, Content, Footer } = Layout;


const Home = () => {
  const navigate = useNavigate()
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(()=>{
    //网页展示设备类型   1.墨水瓶办公本    2.全向麦
    request("POST", "/api/websiteDeviceBase/selectWebsiteDeviceBase", {
      websiteDeviceType: '1',
    }).then((res) => {
      if (process.env.REACT_APP_REQUEST_OK === res.code) {
        // console.log(res.data);
        console.log(JSON.parse(res.data[0].websiteDevicePreviewPicture));
      } else {
        messageApi.open({
          type: 'error',
          content: t(res.code),
        });
      }
    }).catch((err) => console.error(err));
  },[])

  //中英文切换
  const [locale, setLocal] = useState((Boolean(localStorage.getItem('language')) ? true : false));

  const changeLocale = (e) => {
    const localeValue = e.target.value;
    setLocal(localeValue);
    if (localeValue) {
      //英文
      localStorage.setItem('language', localeValue);
      i18n.changeLanguage('en');
    } else {
      //中文
      i18n.changeLanguage('zh');
      localStorage.setItem('language', '');
    }
    // window.location.reload();
  };

  const changeLocaleEn = () => {
    //英文
    localStorage.setItem('language', 'en');
    i18n.changeLanguage('en');
    $("#en").css("color", "#0279AF");
    $("#cn").css("color", "#666666");
  }

  const changeLocaleCn = () => {
    //中文
    i18n.changeLanguage('zh');
    localStorage.setItem('language', '');
    $("#cn").css("color", "#0279AF");
    $("#en").css("color", "#666666");
  }

  //跳转页面
  const toPage = (url,e) => {
    if(url === ""){
      $("#overlay_return").html("<");
      setItemsMobileDevicesState(e);
      return;
    }
    closeOverlay();
    if (url === "5") {
      window.open('https://realsure.jd.com', '_blank');
      return;
    }
    navigate(url);
    window.scrollTo({ top: 1 });
    $("#overlay_return").html("&nbsp;");
    setItemsMobileDevicesState(itemsMobileDevices);
  }

  const itemsPc = [
    {
      label: t('首页'),
      key: '0',
    },
    {
      label: (
        <Popover placement="bottom" arrow={false} content={(
          <div className='popover_tanchu'>
            <div onClick={() => { toPage('/Home/InkScreen?uuid=7807') }} className='popover_tanchu_101 hover_div_pictrue'>
              <div><img src={z78} alt='7-8 inch' /></div>
              <div className='popover_tanchu_text'>7.8英寸</div>
            </div>
            <div onClick={() => { toPage('/Home/InkScreen?uuid=1009') }} className='popover_tanchu_101 hover_div_pictrue'>
              <div><img src={z101} alt='10-1 inch' /></div>
              <div className='popover_tanchu_text'>10.1英寸</div>
            </div>
            <div onClick={() => { toPage('/Home/InkScreen?uuid=1010') }} className='popover_tanchu_103 hover_div_pictrue'>
              <div><img src={z103} alt='10-3 inch' /></div>
              <div className='popover_tanchu_text'>10.3英寸</div>
            </div>
          </div>
        )}>
          {t('墨水屏办公本')}
        </Popover>
      ),
      key: '1',
    },
    {
      label: (
        <Popover placement="bottom" arrow={false} content={(
          <div className='popover_tanchu'>
            <div onClick={() => { toPage('/Home/InkScreen?uuid=s1') }} className='popover_tanchu_yinpin hover_div_pictrue'>
              <div><img src={yinpin} alt='yinpin' style={{ height: "80px" }} /></div>
              <div className='popover_tanchu_text'>会诺音频S1</div>
            </div>
          </div>
        )}>
          {t('全向麦')}
        </Popover>
      ),
      key: '2',
    },
    {
      label: (
        <Popover placement="bottom" arrow={false} content={(
          <div className=''>
            <div onClick={() => { toPage('/Home/OperateFingerpost') }} style={{ margin: "5px 9px 3px 7px" }} className='hover_div_font'>
              <div className='popover_caozuozhinan_text'>{t('操作指南')}</div>
            </div>
            <div onClick={() => { toPage('/Home/ServeMaintain') }} className='hover_div_font'>
              <div className='popover_caozuozhinan_text'>{t('服务和维修')}</div>
            </div>
          </div>
        )}>
          {t('服务支持')}
        </Popover>
      ),
      key: '3',
    },
    {
      label: t('实干家会诺'),
      key: '4',
    },
    {
      label: t('网上商城'),
      key: '5',
    },
  ];

  const itemsMobileDevices = [
    {
      label: t('首页'),
      url: '/Home/Homepage',
      key: '0',
    },
    {
      label: t('墨水屏办公本'),
      key: '1',
      url: "",
      children: [
        {
          label: "7.8英寸",
          key: '103',
          url: "/Home/InkScreen?uuid=7807",
        },
        {
          label: "10.1英寸",
          key: '101',
          url: "/Home/InkScreen?uuid=1009",
        },
        {
          label: "10.3英寸",
          key: '102',
          url: "/Home/InkScreen?uuid=1010",
        }
      ]
    },
    {
      label: t('全向麦'),
      key: '2',
      url: "",
      children: [
        {
          label: "会诺音频S1",
          key: '201',
          url: "/Home/InkScreen?uuid=s1",
        },
      ]
    },
    {
      label: t('服务支持'),
      key: '3',
      url: "",
      children: [
        {
          label: t('操作指南'),
          key: '301',
          url: "/Home/OperateFingerpost",
        },
        {
          label: t('服务和维修'),
          key: '302',
          url: "/Home/ServeMaintain",
        },
      ]
    },
    {
      label: t('实干家会诺'),
      key: '4',
      url: '/Home/UnderstandRealsure',
    },
    {
      label: t('网上商城'),
      key: '5',
      url: "5",
    },
  ];

  const [itemsMobileDevicesState,setItemsMobileDevicesState] = useState(itemsMobileDevices);

  const onClick = (e) => {

  };

  const onSelect = (e) => {
    if (e.key === "0") {
      navigate('/Home/Homepage');
    }
    if (e.key === "4") {
      navigate('/Home/UnderstandRealsure');
    }
    if (e.key === "5") {
      window.open('https://realsure.jd.com', '_blank');
    }
    if (e.key === "101") {
      toPage('/Home/InkScreen?uuid=1009');
    }
    if (e.key === "102") {
      toPage('/Home/InkScreen?uuid=1010');
    }
    if (e.key === "103") {
      toPage('/Home/InkScreen?uuid=7807');
    }
    if (e.key === "201") {
      toPage('/Home/InkScreen?uuid=s1');
    }
    if (e.key === "301") {
      toPage('/Home/OperateFingerpost');
    }
    if (e.key === "302") {
      toPage('/Home/ServeMaintain');
    }
    window.scrollTo({ top: 1 });
  }

  const toLogin = () => {
    window.open(process.env.REACT_APP_URL, '_blank');
  }

  const openOverlay = () => {
    $("#overlay").css("display", "flex");
  }

  const closeOverlay = () => {
    $("#overlay").css("display", "none");
    overlayReturn();
  }

  const overlayReturn = () => {
    $("#overlay_return").html("&nbsp;");
    setItemsMobileDevicesState(itemsMobileDevices);
  }

  return (
    <ConfigProvider locale={locale ? enUS : zhCN}>
    {contextHolder}
      <Layout className="site-layout">
        <Affix offsetTop={0} style={{zIndex: "9999"}}>
          <Header
            style={{
              height: 60,
              padding: 0,
              background: '#000000',
            }}
          >
            <div id="overlay" className="overlay">
              <div id="dialog" className="dialog">
                <div>
                  <div onClick={overlayReturn} id='overlay_return' className='overlay_return'>&nbsp;</div>
                  {/* <img alt='图标' className='overlay_home_titlepng' src={titlepng} /> */}
                  <img onClick={closeOverlay} id="close_btn" className="close_btn" src={guanbi} />

                </div>
                <div className='overlay_home_menu'>
                  {
                    itemsMobileDevicesState.map((itemsMobileDevice, i) => {

                      return (
                        <div key={i} onClick={() => { toPage(itemsMobileDevice.url, itemsMobileDevice.children) }}>{itemsMobileDevice.label}</div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <img alt='图标' className='home_titlepng' src={titlepng} />
            <div className='home_header'>
              <div className='home_menu_parend'>
                <Menu className='home_menu' onSelect={onSelect} onClick={onClick} mode="horizontal" items={itemsPc} />
              </div>
              <div onClick={toLogin} className='login_button'>
                {t('登录')}
              </div>
              <img onClick={openOverlay} id='gengduo_btn' className="gengduo_btn" src={gengduo} />
              {/* <div className='home_fenge'></div> */}
              {/* <div id='cn' onClick={changeLocaleCn} style={{marginRight:'30px',fontSize:'16px',fontFamily:'SourceHanSansCN-Regular, SourceHanSansCN'}}>CN</div>
            <div id='en' onClick={changeLocaleEn} style={{fontSize:'16px',fontFamily:'SourceHanSansCN-Regular, SourceHanSansCN'}}>EN</div> */}
              {/* <Radio.Group className='home_language' value={locale} onChange={changeLocale}>
              <Radio.Button key="en" value={true}>
                EN
              </Radio.Button>
              <Radio.Button key="cn" value={false}>
                CN
              </Radio.Button>
            </Radio.Group> */}
            </div>
          </Header>
        </Affix>
        <Content>
          <div
            style={{
              minHeight: 360,
            }}
          >
            <Outlet />
          </div>
        </Content>
        <Footer
          style={{
            backgroundColor: '#363636',
            textAlign: 'center',
            height: '338px',
            padding: '0',
          }}
        >
          <div className='home_footer'>
            <div className='home_footer_content'>
              <div className='home_footer_header'>
                <div>
                  <div className='home_down_zhu'>{t('墨水屏办公本')}</div>
                  <div onClick={() => { toPage('/Home/InkScreen?uuid=7807') }} className='home_down_fu'>{t('7.8')}</div>
                  <div onClick={() => { toPage('/Home/InkScreen?uuid=1009') }} className='home_down_fu'>{t('10.1')}</div>
                  <div onClick={() => { toPage('/Home/InkScreen?uuid=1010') }} className='home_down_fu'>{t('10.3')}</div>
                </div>
                <div>
                  <div className='home_down_zhu'>{t('全向麦')}</div>
                  <div onClick={() => { toPage('/Home/InkScreen?uuid=s1') }} className='home_down_fu'>{t('会诺音频S1')}</div>
                </div>
                <div>
                  <div className='home_down_zhu'>{t('服务支持')}</div>
                  <div onClick={() => { toPage('/Home/OperateFingerpost') }} className='home_down_fu'>{t('操作指南')}</div>
                  <div onClick={() => { toPage('/Home/ServeMaintain') }} className='home_down_fu'>{t('服务和维修')}</div>
                </div>
                <div>
                  <div className='home_down_zhu'>{t('实干家会诺')}</div>
                  <div onClick={() => { navigate('/Home/UnderstandRealsure'); window.scrollTo({ top: 1 }); }} className='home_down_fu'>{t('了解会诺')}</div>
                  {/* <div onClick={shangwuhezuo} className='home_down_fu'>{t('联系我们')}</div> */}
                </div>
                <div>
                  <div className='home_down_zhu'>{t('网上商城')}</div>
                  <div onClick={() => { window.open('https://realsure.jd.com', '_blank'); }} className='home_down_fu'>{t('京东官方旗舰店')}</div>
                </div>
              </div>
              <div className='home_footer_bottom_right'>
                <div className='home_footer_bottom_right_erweima'>
                  <div><img style={{mixBlendMode: "hard-light"}} className='home_erweima' alt="bannner" src={process.env.REACT_APP_OSS_PREFIX + "xiaohongshu.png"} /></div>
                  <div className='huinuowenzi'>{t('小红书')}</div>
                </div>
                <div className='home_footer_bottom_right_erweima'>
                  <div><img className='home_erweima' alt="bannner" src={process.env.REACT_APP_OSS_PREFIX + "huinuoweibo.png"} /></div>
                  <div className='huinuowenzi'>{t('会诺微博')}</div>
                </div>
                <div className='home_footer_bottom_right_erweima'>
                  <div><img className='home_erweima' alt="bannner" src={process.env.REACT_APP_OSS_PREFIX + "huinuogongzhonghao.png"} /></div>
                  <div className='huinuowenzi'>{t('会诺公众号')}</div>
                </div>
              </div>
            </div>
            <div className='home_down_lianxifangshi'>
              <div className='home_down_lianxifangshi_up'>联系电话：0755-86561998</div>
              <div className='home_down_lianxifangshi_doen'>深圳办公地址：深圳市南山区科发路8号科技园金融基地2栋7F2</div>
            </div>
            <div className='home_down_company'>Copyright 2003-2024 深圳市英唐数码科技有限公司 All Rights Reserved <a style={{ color: "#ffffff" }} href='https://beian.miit.gov.cn/#/Integrated/recordQuery' target="_blank">粤ICP备18008868号-2</a></div>
            <div className='home_down_company_phone'>
              <div>Copyright © 2024 深圳市英唐数码科技有限公司</div>
              <div>
                <a style={{ color: "#ffffff" }} href='https://beian.miit.gov.cn/#/Integrated/recordQuery' target="_blank">粤ICP备18008868号-2</a>
              </div>
            </div>
          </div>
        </Footer>
      </Layout>
    </ConfigProvider>
  );
};
export default Home;