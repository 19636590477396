import { useEffect, useState } from "react";
import "./InkScreen.css";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Affix, message } from 'antd';
import request from '../../utils/request';

const InkScreen = () => {
    const { t } = useTranslation();
    const [messageApi, contextHolder] = message.useMessage();

    const [isHidden, setHidden] = useState(true);

    //图片数组
    const [imgPages, setImgPages] = useState([]);

    //实体类
    const [deviceBase, setDeviceBase] = useState();

    //获取地址栏参数对象
    const [searchParams] = useSearchParams();

    const toPictrue = () => {
        setHidden(true);
        window.scrollTo({ top: 1 });
    }

    const toDetails = () => {
        setHidden(false);
        window.scrollTo({ top: 1 });
    }

    //初始化
    useEffect(() => {
        //网页展示设备类型   1.墨水瓶办公本    2.全向麦
        request("POST", "/api/websiteDeviceBase/selectWebsiteDeviceBase", {
            websiteDeviceCode: searchParams.get("uuid"),
        }).then((res) => {
            if (process.env.REACT_APP_REQUEST_OK === res.code) {
                console.log(JSON.parse(res.data[0].deviceImgList));
                setDeviceBase(res.data[0]);
                setImgPages(JSON.parse(res.data[0].deviceImgList));
                // console.log(JSON.parse(res.data[0].websiteDevicePreviewPicture));
            } else {
                messageApi.open({
                    type: 'error',
                    content: t(res.code),
                });
            }
        }).catch((err) => console.error(err));
        setHidden(true);
    }, [searchParams, messageApi, t]);

    return (
        <>
            {contextHolder}
            <Affix offsetTop={60} style={{ zIndex: "999999" }}>
                <div className="head_page">
                    <div onClick={toPictrue} className="head_page_text">{deviceBase === undefined ? "" : deviceBase.leftDeviceTitle}</div>
                    <div onClick={toDetails} className="head_page_text">{t('技术规格')}</div>
                </div>
            </Affix>
            <div style={{ borderBottom: "1px solid #DCDDDD" }}></div>
            {
                isHidden
                &&
                <div className="img_page">
                    {
                        imgPages.map((imgPage, i) => {
                            return (
                                <img key={i} className="img_page_index" alt={i} src={imgPage.fileUrl} />
                            )
                        })
                    }
                </div>
            }
            {
                !isHidden
                &&
                <div className="lnk_screen_deltails">
                    <div className="lnk_screen_deltails_title">{t('技术规格')}</div>
                    <div className="lnk_screen_deltails_context">
                        {
                            deviceBase.deviceTitle !== ""
                            &&
                            <div className="lnk_screen_deltails_context_model">
                                <div className="lnk_screen_deltails_context_title">{t('产品名称')}</div>
                                <div className="lnk_screen_deltails_context_context">{deviceBase.deviceTitle}</div>
                                <div className="lnk_screen_deltails_context_explain"></div>
                            </div>
                        }
                        {
                            deviceBase.deviceSoundRadius !== ""
                            &&
                            <div className="lnk_screen_deltails_context_model">
                                <div className="lnk_screen_deltails_context_title">{t('拾音半径')}</div>
                                <div className="lnk_screen_deltails_context_context">{deviceBase.deviceSoundRadius}</div>
                                <div className="lnk_screen_deltails_context_explain"></div>
                            </div>
                        }
                        {
                            deviceBase.deviceHornFrequencyResponse !== ""
                            &&
                            <div className="lnk_screen_deltails_context_model">
                                <div className="lnk_screen_deltails_context_title">{t('喇叭频率响应')}</div>
                                <div className="lnk_screen_deltails_context_context">{deviceBase.deviceHornFrequencyResponse}</div>
                                <div className="lnk_screen_deltails_context_explain"></div>
                            </div>
                        }
                        {
                            deviceBase.devicePowerInput !== ""
                            &&
                            <div className="lnk_screen_deltails_context_model">
                                <div className="lnk_screen_deltails_context_title">{t('电源输入')}</div>
                                <div className="lnk_screen_deltails_context_context">{deviceBase.devicePowerInput}</div>
                                <div className="lnk_screen_deltails_context_explain"></div>
                            </div>
                        }
                        {
                            deviceBase.deviceWeight !== ""
                            &&
                            <div className="lnk_screen_deltails_context_model">
                                <div className="lnk_screen_deltails_context_title">{t('产品重量')}</div>
                                <div className="lnk_screen_deltails_context_context">{deviceBase.deviceWeight}</div>
                                <div className="lnk_screen_deltails_context_explain"></div>
                            </div>
                        }
                        {
                            deviceBase.deviceMemorySpace !== ""
                            &&
                            <div className="lnk_screen_deltails_context_model">
                                <div className="lnk_screen_deltails_context_title">{t('存储空间')}</div>
                                <div className="lnk_screen_deltails_context_context">{deviceBase.deviceMemorySpace}</div>
                                <div className="lnk_screen_deltails_context_explain"></div>
                            </div>
                        }
                        {
                            deviceBase.deviceWirelessConnections !== ""
                            &&
                            <div className="lnk_screen_deltails_context_model">
                                <div className="lnk_screen_deltails_context_title">{t('无线连接')}</div>
                                <div className="lnk_screen_deltails_context_context">{deviceBase.deviceWirelessConnections}</div>
                                <div className="lnk_screen_deltails_context_explain"></div>
                            </div>
                        }
                        {
                            deviceBase.deviceResolutionRatio !== ""
                            &&
                            <div className="lnk_screen_deltails_context_model">
                                <div className="lnk_screen_deltails_context_title">{t('分辨率')}</div>
                                <div className="lnk_screen_deltails_context_context">{deviceBase.deviceResolutionRatio}</div>
                                <div className="lnk_screen_deltails_context_explain"></div>
                            </div>
                        }
                        {
                            deviceBase.deviceFrontLight !== ""
                            &&
                            <div className="lnk_screen_deltails_context_model">
                                <div className="lnk_screen_deltails_context_title">{t('前光')}</div>
                                <div className="lnk_screen_deltails_context_context">{deviceBase.deviceFrontLight}</div>
                                <div className="lnk_screen_deltails_context_explain"></div>
                            </div>
                        }
                        {
                            deviceBase.deviceElectromagneticPen !== ""
                            &&
                            <div className="lnk_screen_deltails_context_model">
                                <div className="lnk_screen_deltails_context_title">{t('电磁笔')}</div>
                                <div className="lnk_screen_deltails_context_context">{deviceBase.deviceElectromagneticPen}</div>
                                <div className="lnk_screen_deltails_context_explain"></div>
                            </div>
                        }
                        {
                            deviceBase.deviceSystem !== ""
                            &&
                            <div className="lnk_screen_deltails_context_model">
                                <div className="lnk_screen_deltails_context_title">{t('系统')}</div>
                                <div className="lnk_screen_deltails_context_context">{deviceBase.deviceSystem}</div>
                                <div className="lnk_screen_deltails_context_explain"></div>
                            </div>
                        }
                        {
                            deviceBase.deviceCpu !== ""
                            &&
                            <div className="lnk_screen_deltails_context_model">
                                <div className="lnk_screen_deltails_context_title">{t('CPU')}</div>
                                <div className="lnk_screen_deltails_context_context">{deviceBase.deviceCpu}</div>
                                <div className="lnk_screen_deltails_context_explain"></div>
                            </div>
                        }
                        {
                            deviceBase.deviceBatteryCapacity !== ""
                            &&
                            <div className="lnk_screen_deltails_context_model">
                                <div className="lnk_screen_deltails_context_title">{t('电池容量')}</div>
                                <div className="lnk_screen_deltails_context_context">{deviceBase.deviceBatteryCapacity}</div>
                                <div className="lnk_screen_deltails_context_explain"></div>
                            </div>
                        }
                        {
                            deviceBase.devicePort !== ""
                            &&
                            <div className="lnk_screen_deltails_context_model">
                                <div className="lnk_screen_deltails_context_title">{t('接口')}</div>
                                <div className="lnk_screen_deltails_context_context">{deviceBase.devicePort}</div>
                                <div className="lnk_screen_deltails_context_explain"></div>
                            </div>
                        }
                        {
                            deviceBase.deviceProductSize !== ""
                            &&
                            <div className="lnk_screen_deltails_context_model">
                                <div className="lnk_screen_deltails_context_title">{t('产品机身尺寸')}</div>
                                <div className="lnk_screen_deltails_context_context">{deviceBase.deviceProductSize}</div>
                                <div className="lnk_screen_deltails_context_explain">{t('此数据为人工测量可能存在士1mm误差仅供参考')}</div>
                            </div>
                        }
                        {
                            deviceBase.deviceProductFunction !== ""
                            &&
                            <div className="lnk_screen_deltails_context_model">
                                <div className="lnk_screen_deltails_context_title">{t('产品功能')}</div>
                                <div className="lnk_screen_deltails_context_context">{deviceBase.deviceProductFunction}</div>
                                <div className="lnk_screen_deltails_context_explain"></div>
                            </div>
                        }
                    </div>
                </div>
            }

        </>
    );
}
export default InkScreen;